import { v4 as uuid } from 'uuid';

export class FetchError {
  constructor(
    message: string,
    errorCode?: number | string,
    status?: number | string
  ) {
    this.message = message;
    this.errorCode = errorCode;
    this.status = status;
  }

  message: string;
  errorCode?: number | string;
  status?: number | string;
}

export async function fetchAsync<T extends object>(
  url: string,
  postData: any,
  fetchOptions: any = {},
  cancelKey?: string,
  postAsJson?: boolean
): Promise<T | FetchError> {
  var formData = new FormData();

  var id = uuid();

  if (!postAsJson && postData) {
    convertModelToFormData(postData, formData);
  }

  if (cancelKey) {
    var key = `${url}_${cancelKey}`;
    abort(url, cancelKey);

    var queueItem = {
      id,
      key,
      abort: new AbortController(),
    };

    _pendingQueue.push(queueItem);

    fetchOptions.signal = queueItem.abort.signal;
  }

  const options = {
    method: 'POST',
    credentials: 'same-origin',
    body: postAsJson ? JSON.stringify(postData) : formData,
    ...fetchOptions,
  };

  if (options.method !== 'POST') options.body = null;

  if (postAsJson) {
    if (!options.headers) {
      options.headers = {};
    }
    options.headers['Content-Type'] = 'application/json';
  }

  try {
    var response = await fetch(`${url}`, options);
    var responseItem = await getResponseItem(response);

    removeFromQueue(id);

    if (response.ok) {
      if (!responseItem) {
        return undefined as any;
      }

      const json = GetJson(responseItem);

      if (json.isError) {
        return new FetchError(
          json.error.message,
          json.error.errorCode,
          json.error.errorCode
        );
      } else {
        //everything okay
        return json;
      }
    } else {
      //fetch error
      return new FetchError(responseItem, response.status, response.status);
    }
  } catch (ex) {
    //something wrong
    removeFromQueue(id);
    // @ts-ignore
    return new FetchError(ex.toString(), 0, 0);
  }
}

function GetJson(str: string) {
  try {
    const ret = JSON.parse(str);

    return ret;
  } catch (e) {}

  return str;
}

function getResponseItem(response?: Response): Promise<any> {
  if (!response) return new Promise<any>(r => r(null));
  if (response.text) return response.text();
  return new Promise<any>(r => r(response));
}

function convertModelToFormData(
  data: any = {},
  form: any = null,
  namespace: string = ''
) {
  let files: any = {};
  let model: any = {};
  for (let propertyName in data) {
    if (
      data.hasOwnProperty(propertyName) &&
      data[propertyName] instanceof File
    ) {
      files[propertyName] = data[propertyName];
    } else {
      model[propertyName] = data[propertyName];
    }
  }

  model = JSON.parse(JSON.stringify(model));
  let formData = form || new FormData();

  for (let propertyName in model) {
    if (
      !model.hasOwnProperty(propertyName) ||
      (model[propertyName] !== 0 &&
        model[propertyName] !== '' &&
        model[propertyName] !== false &&
        !model[propertyName])
    )
      continue;
    let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
    if (model[propertyName] instanceof Date)
      formData.append(formKey, model[propertyName].toISOString());
    // else if (model[propertyName] instanceof File) {
    // 	formData.append(formKey, model[propertyName]);
    // }
    else if (model[propertyName] instanceof Array) {
      model[propertyName].forEach((element: any, index: number) => {
        const tempFormKey = `${formKey}[${index}]`;
        if (typeof element === 'object')
          convertModelToFormData(element, formData, tempFormKey);
        else formData.append(tempFormKey, element.toString());
      });
    } else if (
      typeof model[propertyName] === 'object' &&
      !(model[propertyName] instanceof File)
    )
      convertModelToFormData(model[propertyName], formData, formKey);
    else {
      formData.append(formKey, model[propertyName].toString());
    }
  }

  for (let propertyName in files) {
    if (files.hasOwnProperty(propertyName)) {
      formData.append(propertyName, files[propertyName]);
    }
  }
  return formData;
}

type QueueItem = {
  id: string;
  key: string;
  abort: AbortController;
};

const abort = (url: string, cancelKey: string) => {
  const key = `${url}_${cancelKey}`;
  var filter = _pendingQueue.filter(e => e.key === key);

  if (filter.length > 0) {
    filter.forEach(found => {
      found.abort.abort();
      removeFromQueue(found.id);
    });
  }
};

const removeFromQueue = (id: string) => {
  var found = _pendingQueue.find(e => e.id === id);

  if (found) {
    var i = _pendingQueue.indexOf(found);
    _pendingQueue.splice(i, 1);
  }
};

const _pendingQueue: QueueItem[] = [];

export default fetchAsync;
