// import { msalInstance } from "index";
import { IPublicClientApplication } from '@azure/msal-browser';
import { FetchError } from '../fetch';

const getAuthBearerToken = (
  msalInstance: IPublicClientApplication,
  loginRequest: {
    scopes: string[];
  }
) => {
  var userAccount = msalInstance.getAllAccounts()[0];

  var authValue = msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account: userAccount,
    })
    .then(msalResopnse => {
      return { token: msalResopnse.accessToken };
    })
    .catch(
      (reason: {
        errorCode: string;
        errorMessage: string;
        subError: string;
        name: string;
      }) => {
        return new FetchError(
          reason.errorMessage,
          reason.errorCode,
          reason.name
        );
      }
    );

  return authValue;
};

export default getAuthBearerToken;
